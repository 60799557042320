html {
  max-width: 100vw;
  overflow: auto;
  overflow-x: hidden;
  background-color: white;
  color: #66585b;
}

.noe-font-title {
  font-size: 38px;
  line-height: 1.1;
  font-family: "Nominee";
  font-style: normal;
  font-weight: bolder;
  font-size: 80px;
  line-height: 90%;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.82);
}

.section-2-paragraph-font {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  font-family: "Nominee";
  font-weight: 300;
}

.segoe-font-title {
  font-family: "Nominee";
  line-height: 28.8px;
  font-size: 24px;
  font-weight: 400;
}

.App {
  text-align: center;
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  position: relative;
  overflow: hidden;
}

/* header start */

.header-container {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 50;
  background-color: #f8f9f6;
  grid-template-columns: 1fr 4fr 1fr;
  display: grid;
}
#bag {
  margin-top: 7px;
}

.header-svg {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
}

.icon-wrapper {
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.bag-badge {
  background: #fa6969;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 30px;
  margin-bottom: -8px;
}
.bag-container {
  display: inline;
}
.footer-svg {
  margin-left: 50px;
  margin-top: 10px;
}

.emw-logo img {
  width: 280px;
  height: auto;
}
.verify-logo {
  width: 290px;
  height: auto;
}
.emw-logo {
  left: -37%;
  position: absolute;
  left: 30px;
}
.nav-items {
  display: flex;
  align-items: center;
  gap: 20px;
}
.nav-items li {
  list-style: none;
  font-family: "Nominee";
}
a,
a > span {
  position: relative;
  color: inherit;
  text-decoration: none;
}
a:before,
a:after,
a > span:before,
a > span:after {
  /* content: '';
  position: absolute;
  transition: transform 0.5s ease; */
}
a {
  text-decoration: underline;
  color: #846032;
  /* display: inline-flex;
  padding-top: 10px;
  padding-bottom: 5px;
  overflow: hidden; */
}
a:before {
  /* left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #717171;
  transform: translateX(-100%); */
}
a:hover:before {
  /* transform: translateX(0); */
}
a:hover {
  color: #444343;
}
.hero-h3 {
  font-weight: 300;
  text-align: left;
  z-index: 0;
  margin: 0;
  font-size: 20px;
  font-family: "Nominee";
}
.hero-h3 a {
  color: #846032;
}
.cart_icon {
  padding-top: 10px;
  position: absolute;
  top: 60px;
  right: 40px;
}
.p_break {
  display: none;
}

.subscribe_monthly {
  text-align: left;
  max-width: 1280px;
  margin: 0 auto;
  max-width: 90%;
  display: flex;
  align-items: center;
}
/* header end */

/* slider start */
.slideout-wrapper {
  position: fixed;
  right: -600px;
  top: 0;
  width: 500px;
  height: 100vh;

  background-color: white;
  z-index: 999;
  transition: 0.4s ease-in-out all;
  box-shadow: 13px 20px 20px 20px rgba(0, 0, 0, 0.278);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.slideout-wrapper.active-slide {
  right: 0px;
}

.slideout-header-wrapper {
  position: relative;
  /* margin-bottom: 2rem; */
  height: 63px;
}
.slideout-h2 {
  position: relative;
  font-family: "Nominee";
}
.container {
  max-width: 1280px;
  /* width: 90%; */
  margin: 0 auto !important;
}
.slideout-header-x-wrapper {
  position: absolute;
  top: 20px;
  right: 17px;
  height: 33px;
  width: 33px;
  border-radius: 50%;
  padding: 0.16rem;
  width: 16px;
  height: 16px;
  align-items: center;
}
.slideout-header-x-wrapper svg {
  top: 0px;
  right: 0px;
  margin-top: 5px;
}
.hiddendiv {
  transition: 0.4s ease-in-out all;
  display: none;
  height: 0px;
  width: 0px;
  position: relative;
}

.hiddendiv.overlay {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.417);
  z-index: 997;
}
.slideout-bottom-wrapper {
  width: 100%;
  background-color: #f5f4f1;
  min-height: 250px;
}

.checkout-button {
  background: #2d2d2d;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
  padding: 16px 24px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  width: 392px;
  border: none;
  cursor: pointer;
}
.checkout-button:hover {
  transition: all 0.2s ease;
  background: #00000094;
}

.slideout-products-wrapper {
  border-top: 1px solid rgba(0, 0, 0, 0.376);
  overflow: auto;
  height: 80%;
  border-bottom: 1px solid #66585b;
}

.slideout-product-wrapper {
  max-height: 120px;
  overflow: hidden;
  display: flex;
  /* justify-content: space-between; */
  gap: 8px;
  padding: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.376);
}

.slideout-products-left {
  flex: 0.2;
}

.slideout-products-center {
  padding-left: 2px;
  width: 100%;
}

.slideout-products-center h3 {
  color: rgba(0, 0, 0, 0.329);
  font-weight: normal;
}

.slideout-products-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* flex: 0.2; */
  align-items: flex-end;
}

.slide-products-image {
  object-fit: cover;
  height: 100%;
  overflow: hidden;
  max-width: 100px;
}

.slideout-product-button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: white;
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.58);
  gap: 3px;
  margin-left: 8px;
}
.remove-span {
  /* font-family: Theinhardt-Regular; */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */

  display: flex;
  align-items: center;
  cursor: pointer;
}
.remove-icon {
  margin-top: 4px;
  cursor: pointer;
}

.quantity-selector input {
  background-color: white;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.128);
  outline: none;
  padding: 0.2rem 0.5rem;
  max-width: 70px;
  min-height: 28px;
}
.quantity-price {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.slideout-products-right-price {
  /* font-family: Theinhardt-Regular; */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  white-space: nowrap;
  color: #66585b;
}

.slideout-products-right-x svg {
  color: rgba(0, 0, 0, 0.459);
  cursor: pointer;
}

.slideout-product-title {
  font-size: 15px;
}

.slideout-product-title h2 {
  text-align: left;
  font-family: "Nominee";
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.82);
}

.slideout-bottom-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -0.2rem 0.8rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.212);
}

.slidder-shipping {
  /* font-family: Theinhardt-Regular; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.82);
  margin-left: 24px;
  font-size: 18px;
  margin-right: 24px;
}

.shipping-dollar {
  font-family: "Nominee";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 100%;
  text-align: right;
  color: rgba(0, 0, 0, 0.82);
  bottom: 4px;
  position: relative;
  right: 2px;
}
.dollar-sign {
  font-family: "Nominee";
  font-style: normal;
  font-weight: bolder;
  font-size: 12px;
  line-height: 100%;
  text-align: right;
  position: relative;
  top: -11px;
}
.price-sign {
  /* font-family: Theinhardt-Regular; */
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 100%;
  text-align: right;
  position: relative;
  top: -7px;
}
.subtotal-h3 {
  /* font-family: Theinhardt-Regular; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  color: rgba(0, 0, 0, 0.82);
  margin-left: 24px;
}
.subtotal-cost {
  /* font-family: Theinhardt-Regular; */
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 100%;
  text-align: right;
  margin-right: 24px;
  color: #66585b;
}
.slideout-dropdown {
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 3px;
  width: 104px;
  height: 32px;
  text-align: left;
  font-size: 14px;
  padding-left: 13px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  z-index: -1;
}

.slideout-dropdown {
  vertical-align: middle;
  background: url("../Css/imgs/carett.png") no-repeat 5.4rem;
  background-size: 8px;
}

.caret {
  border: solid red !important;
  position: relative;
  left: 10px !important;
}
.item-subscription {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  color: rgba(0, 0, 0, 0.58);
  margin-top: -6px;
  margin-bottom: 20px;
  text-align: left;
}
.checkout-continue-shopping {
  font-family: "Nominee";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  color: rgba(0, 0, 0, 0.82);
  cursor: pointer;
}
.checkout-continue-shopping:hover {
  transition: all 0.2s ease;
  color: #767676;
}
@media only screen and (max-width: 850px) {
  .slideout-wrapper {
    position: fixed;
    right: -850px;
    top: 0;
    width: 98vw;
    height: 100vh;
    background-color: white;
    z-index: 999;
    transition: 0.5s ease-in-out all;
  }

  .slideout-wrapper.active-slide {
    right: 0px;
  }
  .slideout-product-title h2 {
    font-size: 14px;
  }
}
@media only screen and (max-width: 920px) {
  .item-subscription {
    font-size: 12px;

    text-align: left;
  }
  .slideout-products-center {
    justify-content: center;
  }
  .slideout-product-title h2 {
    font-size: 18px;
  }

  .checkout-button {
    width: 370px;
  }
}
@media only screen and (max-width: 414px) {
  .checkout-button {
    width: 350px;
  }
}
@media only screen and (max-width: 411px) {
  .checkout-button {
    width: 330px;
  }
}
@media only screen and (max-width: 375px) {
  .slideout-products-right-price {
    margin-left: 0px;
    font-size: 14px;
  }
  .item-subscription {
    font-size: 11px;

    text-align: left;
  }
  .slideout-products-center {
    justify-content: center;
  }
  .slideout-product-title h2 {
    font-size: 16px;
  }
  .subtotal-cost {
    font-size: 20px;
  }
  .price-sign {
    top: -3px;
  }
  .slideout-product-wrapper {
    gap: 0;
  }
  .slideout-dropdown {
    vertical-align: middle;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABmJLR0QA/wD/AP+gvaeTAAAAo0lEQVRIie3STaqBYRTA8Z+ukis7sgwTQzuxAckejFEGUiZiG5JV3G69YeBVb74/jtnzrzN7Or9OPaRSqcgGyLAPmizfedE2EDnN5hrUxC4Q2eU7r9YLhLq3EPjBMACZoHwPgl+sPkCWqD5CTtUwfwNZoP4sUrxs9gIyfeWS8yoYP4GM8rcfVULnDtJ3/ERhtfBXAP7RjgSKNbDOp/EtJJWK6QBceqOraqxeeAAAAABJRU5ErkJggg==)
      no-repeat 4.8rem;
    background-size: 8px;
  }

  .slideout-dropdown {
    width: 90px;
    font-size: 14px;
    padding-left: 5px;
  }
  .slideout-product-button {
    margin-left: 0;
  }
  .quantity-price {
    gap: 5px;
  }
  .dollar-sign {
    top: -6px;
  }
}

@media only screen and (max-width: 360px) {
  .checkout-button {
    width: 310px;
  }
  .item-subscription {
    font-size: 11px;

    text-align: left;
  }
  .slideout-products-center {
    justify-content: center;
  }
  .slideout-product-title h2 {
    font-size: 16px;
  }
  .subtotal-cost {
    font-size: 20px;
  }
  .quantity-price {
    gap: 2px;
  }
}
@media only screen and (max-width: 320px) {
  .checkout-button {
    width: 280px;
  }

  .price-sign {
    top: 0;
  }
  .item-subscription {
    font-size: 10px;

    text-align: left;
  }
  .slideout-products-center {
    justify-content: center;
  }
  .slideout-product-title h2 {
    font-size: 14px;
  }
  .subtotal-cost {
    font-size: 18px;
  }
  .slideout-dropdown {
    width: 70px;
    font-size: 11px;
  }
  .remove-span {
    font-size: 11px;
  }
  .slideout-products-right-price {
    font-size: 13px;
  }
  .remove-icon {
    width: 13px;
    height: 13px;
  }

  .slideout-dropdown {
    vertical-align: middle;
    background: url("../Css/imgs/carett.png") no-repeat 3.7rem;
    background-size: 8px;
    padding-left: 3px;
  }
}
@media only screen and (max-width: 280px) {
  .slide-products-image {
    max-width: 90px;
  }
  .slideout-product-title h2 {
    font-size: 12px;
  }
  .item-subscription {
    font-size: 9px;
  }
  .slideout-product-button {
    margin-left: 0;
  }
  .remove-span {
    font-size: 10px;
  }
  .slideout-products-right-price {
    font-size: 12px;
  }
  .slideout-products-center {
    padding-left: 0rem;
  }
  .subtotal-cost {
    font-size: 16px;
  }
  .checkout-button {
    width: 250px;
  }

  .slideout-dropdown {
    vertical-align: middle;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABmJLR0QA/wD/AP+gvaeTAAAAo0lEQVRIie3STaqBYRTA8Z+ukis7sgwTQzuxAckejFEGUiZiG5JV3G69YeBVb74/jtnzrzN7Or9OPaRSqcgGyLAPmizfedE2EDnN5hrUxC4Q2eU7r9YLhLq3EPjBMACZoHwPgl+sPkCWqD5CTtUwfwNZoP4sUrxs9gIyfeWS8yoYP4GM8rcfVULnDtJ3/ERhtfBXAP7RjgSKNbDOp/EtJJWK6QBceqOraqxeeAAAAABJRU5ErkJggg==)
      no-repeat 3.4rem;
    background-size: 7px;
    padding-left: 3px;
  }

  .slideout-dropdown {
    width: 65px;
    font-size: 10px;
  }
  .slideout-product-wrapper {
    padding: 0.5rem 0.5rem 1.5rem 0.5rem;
  }
  .quantity-price {
    gap: 0;
  }
  .dollar-sign {
    top: -3px;
  }
}

/* hero start */

.hero-container {
  max-width: 90%;
  margin: 0 auto;
  z-index: 1;
  display: flex;
  align-items: center;
  padding-top: 5em;
  margin-bottom: 50px;
}

/* .hero-container::before {
  position: absolute;
  content: "";
  background: linear-gradient(rgba(0, 0, 0, 0.329), rgba(0, 0, 0, 0.671));
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
} */

.hero-text-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-top: 16px;
}

.hero-h1 {
  z-index: 1;
  font-size: 35px;
  text-align: left;
  margin: 0;
  font-weight: 400;
  margin-bottom: 15px;
}

.story-h1 {
  z-index: 1;
  font-size: 26px;
  text-align: center;
  font-weight: 200;
  letter-spacing: 6px !important;
  color: #846032 !important;
}

.product_section_header {
  font-size: 35px;
  max-width: 90%;
  margin: 0 auto;
  text-align: left;
  padding-bottom: 30px;
  font-weight: 500;
}
.product-grid-wrapper {
  padding-bottom: 1em;
}
.color-box {
  width: 50%;
  position: relative;
  /* background: #f4d8f6; */
  /* margin-top: 25px; */
}

@media screen and (max-width: 960px) {
  .hero-h3 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 920px) {
  .color-box {
    grid-row: 1;
    left: 0;
    margin: 0 auto;
    padding-bottom: 54px;
    width: 400px;
    height: 300px;
  }
}

@media only screen and (max-width: 414px) {
  .color-box {
    width: 328px;
    height: 250px;
  }
  .hero-text-container {
    grid-row-gap: 15px;
  }
  .hero-h1 {
    margin-bottom: 0;
  }

  .product_section_header {
    padding-bottom: 0;
    padding-top: 20px;
  }
}
@media only screen and (max-width: 375px) {
  .hero-h3 {
    max-width: 31ch;
  }
}
@media only screen and (max-width: 280px) {
  .color-box {
    width: 300px;
  }
  .hero-h3 {
    font-size: 16px;
    max-width: 29ch;
  }
}

/* hero end */

/* section2 start */
@media all and (max-width: 972px) {
  .product-section-wrapper {
    display: flex;
    width: 90%;
    flex-direction: column;
  }
}

.section2-right {
  width: 28%;
  align-self: flex-start;
  position: sticky;
  top: 0;
  height: auto;
  display: flex;
  flex-direction: column;
  height: 10%;
}

.section-2-article-link {
  color: inherit;
}

.section2-right-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 1rem;
}

.right-grid-box {
  height: 110px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  background-position: center;
  object-fit: cover;
}

.section2-verticle-line {
  width: 1px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section2-left-picture-container {
  max-height: 385px;
  max-width: 350px;
  min-width: 280px;
  overflow: hidden;
  position: relative;
}

.section2-left-container-left {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.lazy-load-image-loaded {
  width: fit-content;
}

.section2-bottom-header {
  margin-left: 24px;
  margin-top: 15px;

  padding-left: 15px;
  display: flex;
  flex-direction: column;
  width: 60%;
}

.section-2-bottom-header-button-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  /* margin-top: 2.5rem; */
  flex-direction: column;
  position: relative;
  /* min-width: 79%; */
  /* width: 100%; */
  align-items: space-between;
  justify-content: flex-end !important;
  margin-top: auto;
}

.product-button-dropdown1 {
  height: 0px;
  max-height: 0px !important;
  width: 100%;
  overflow: hidden;
  margin-top: 1.3rem;
  position: absolute;
  top: 60px;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  transition: 0.3s ease-in-out all;
}
.product-button-dropdown1.button-dropdown-open1 {
  max-height: 300px !important;
  height: 100%;
  opacity: 1;
}

.product-button-dropdown2 {
  height: 100%;
  max-height: 300px !important;
  width: 100%;
  overflow: hidden;
  margin-top: 1.3rem;
  opacity: 0;
  transition: 0.3s ease-in-out all;
}
.product-button-dropdown2.button-dropdown-open2 {
  opacity: 1;
}

.dropdown-select {
  padding: 1rem;
  border: none;
  border-bottom: 1px solid #66585b;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.section-2-bottom-header-button {
  padding: 0.9rem 2rem 0.9rem;
  background-color: #f0ece1;
  outline: none;
  border: 0px solid rgba(0, 0, 0, 0.178);
  font-size: 18px;
  font-family: "Nominee";
  color: #66585b;
  letter-spacing: 0.01em;
  transition: 0.2s ease-in-out all;
  cursor: pointer;
  white-space: nowrap;
  margin-right: 15px;
  min-width: 210px;
  color: #ffff;
  background-color: #846032;
  /* font-weight: 600; */
}
.section-2-bottom-header-button:hover {
  background-color: #936b37;
  color: white;
}
.section-2-bottom-header-button:hover {
  color: white;
}
@media all and (max-width: 1250px) {
  .section-2-bottom-header-button {
    min-width: 150px;
    font-size: 18px;
  }
}

@media all and (max-width: 1087px) {
  .section-2-bottom-header-button {
    min-width: 130px;
    font-size: 12px;
  }
}
@media all and (max-width: 560px) {
  .section-2-bottom-header-button {
    min-width: 230px;
    font-size: 16px;
    width: 100%;
    margin: 0;
    margin-bottom: 4px;
  }
}

.section2-bottom-paragraph {
  opacity: 1;
}

.bottom-author {
  font-size: 12px;
  text-align: left;
  padding-top: 1rem;
}

.section2-bottom-author span {
  opacity: 0.5;
}
.product-button-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 16px;
}

.collection-container {
  display: flex;
  margin-bottom: 55px;
  max-width: 90%;
  /* margin-left: auto;
	margin-right: auto; */
  background-color: white;
  border: solid 16px white;
  margin: 0 auto;
  margin-bottom: 40px;
}
.segeo-font-title.wine-title {
  text-align: left;
  font-family: "Nominee";
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  width: 90%;
  margin-top: 10px;
  color: #846032;
}
.wine-collection-title {
  text-align: left;
  margin-bottom: 0;
  font-family: "Nominee";
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  margin: 0;
  color: #846032;
}

.price-divider {
  color: #cccccc;
  font-family: "Nominee";
}

.limited {
  position: absolute;
  color: white;
  font-weight: 600;
  font-size: 12px;
  top: 0;
  left: 0;
  background: #66585b;
  font-family: "Nominee";
  width: 118px;
  height: 28px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1086px) {
  .section-2-bottom-header-button-wrapper {
    bottom: initial;
    padding-top: 40px;
  }
  .product-button-wrapper {
    gap: 10px;
  }
}
@media screen and (max-width: 1100px) {
  .section-2-bottom-header-button.first-purchase-btn {
    white-space: nowrap;
  }
  .section-2-bottom-header-button.second-purchase-btn {
    white-space: nowrap;
  }
  .product-section-wrapper {
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 920px) {
  .section2-left-picture-container {
    flex-direction: column;
    max-width: 100%;
    height: auto;
    max-height: 800px;
  }

  .section2-left-container-left {
    margin-bottom: 1rem;
    width: 100%;
  }
}

@media only screen and (max-width: 893px) {
  .section2-bottom-header {
    width: 40%;
    margin: 20px;
    margin-top: 0;
  }
  .section-2-bottom-header-button-wrapper {
    margin-top: 0;
  }
  .segeo-font-title.wine-title {
    width: 100%;
  }
  .section-2-bottom-header-button.first-purchase-btn {
    padding: 0.8rem 0.8rem 0.8rem;
    font-size: 14px;
  }
  .section-2-bottom-header-button.second-purchase-btn {
    padding: 0.8rem 0.8rem 0.8rem;
    font-size: 14px;
  }
  .section2-left-container-left {
    height: 407px;
  }
}

@media only screen and (max-width: 700px) {
  .product-section-wrapper {
    width: 100%;
  }

  .section2-left,
  .section2-right {
    width: 100%;
  }

  .section2-right-grid-container {
    grid-template-columns: 1fr 1fr;
  }

  .right-grid-box {
    min-height: 160px;
  }

  .section2-verticle-line {
    display: none;
  }

  .bottom-author {
    padding-top: 0px;
  }

  .section-2-bottom-header-button-wrapper {
    margin-bottom: 0.5rem;
    min-width: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 850px) {
  .section2-bottom-header {
    width: 45%;
    margin: 20px;
  }
  .product-button-wrapper {
    margin-top: 10px 0;
    gap: 10px;
  }
  .hero-h3 {
    width: initial;
  }
}
@media only screen and (max-width: 920px) {
  .collection-container {
    flex-direction: column;
    width: 90%;
    align-items: center;
    min-height: 500px;
  }
  .section2-left-picture-container {
    width: 100%;
    height: auto;
  }
  .section2-bottom-header {
    margin: 0 auto;
    height: auto;
    padding: 2px;
    width: 100%;
  }
  .section2-left-container-left {
    height: auto;
  }

  .product-button-wrapper {
    justify-content: flex-start;
    margin-top: 10px 0;
    gap: 10px;
  }
  .product-grid-wrapper {
    margin-top: 30px;
  }
  .limited {
    display: none;
  }

  .wine-collection-title {
    font-size: 24px;
  }
  .segeo-font-title.wine-title {
    font-size: 16px;
    width: 90%;
  }

  .section-2-bottom-header-button.first-purchase-btn {
    padding: 0.8rem 0.8rem 0.8rem;
    font-size: 13px;
  }
  .section-2-bottom-header-button.second-purchase-btn {
    padding: 0.8rem 0.8rem 0.8rem;
    font-size: 13px;
  }
}

@media only screen and (max-width: 529px) {
  .collection-container {
    padding-bottom: 5%;
  }
}
@media only screen and (max-width: 525px) {
  .collection-container {
    padding-bottom: 0%;
  }
  .product-grid-wrapper {
    margin-bottom: 1rem;
    padding-bottom: 2rem;
  }
}

@media only screen and (max-width: 450px) {
  .product-button-dropdown1 {
    top: 60px;
  }

  .product-button-wrapper {
    margin-top: 0.8rem;
    gap: 6px;
    grid-gap: 6px;
  }
}

@media only screen and (max-width: 414px) {
  .product-section-wrapper {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 375px) {
  .collection-container {
    width: 300px;
    padding-bottom: 10px;
    display: flex;
    height: 100%;
    min-height: 200px;
    position: relative;
  }
  .product-button-wrapper {
    gap: 4px;
    grid-gap: 4px;
    margin-left: -2px;
    font-size: 8px;
  }
  .section-2-bottom-header-button.first-purchase-btn {
    font-size: 13px;
  }
  .section-2-bottom-header-button.second-purchase-btn {
    font-size: 13px;
  }
}

@media only screen and (max-width: 320px) {
  .section-2-bottom-header-button.first-purchase-btn {
    padding: 0.6rem 0.6rem 0.6rem;
    font-size: 11px;
  }
  .section-2-bottom-header-button.second-purchase-btn {
    padding: 0.6rem 0.6rem 0.6rem;
    font-size: 11px;
  }
  .collection-container {
    width: 250px;
    padding-bottom: 1%;
  }

  .product-button-wrapper,
  .section-2-bottom-header-button-wrapper {
    margin-top: 0;
    padding-bottom: 0%;
    margin-bottom: 5px;
  }
  .segeo-font-title.wine-title {
    height: auto;
    display: flex;
  }
}
@media only screen and (max-width: 280px) {
  .collection-container {
    border: solid 8px white;
  }
}
/* section2 end */

/* section3 start */

.section3-right {
  width: 34%;
}

.section3-right-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5rem;
}

.section3-third-section {
  width: 100%;
}

.section3-right-grid-container {
  display: grid;
  grid-template-columns: 1fr;
}

.section-3-right-grid-box {
  min-height: 110px;
  min-width: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
}

.section-3-right-grid-box-left {
  width: 60%;
}

.section-3-right-grid-box-left {
  margin: 0rem 1rem 0rem 0rem;
}

.section-3-right-horizontal-line {
  width: 100%;
  height: 1px;
  background-color: #66585b;
  outline: none;
  border: none;
  opacity: 0.6;
}

@media only screen and (max-width: 950px) {
  .section3-third-section {
    width: 100%;
  }

  .section-3-right-grid-box {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section-3-right-grid-box-left {
    width: 100%;
    margin: 1rem 0rem;
  }

  .section-3-right-horizontal-line {
    display: none;
  }

  .section-3-right-grid-box span {
    max-height: 180px;
  }
}

@media only screen and (max-width: 700px) {
  .section2-left,
  .section2-right {
    width: 100%;
  }

  .section3-third-section {
    width: 100%;
  }
}

/* section3 end */
/* */

.footer_fixed {
  min-height: 50px;
  /* height: 169px; */

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  border-top: solid 1px #5f5e5e;
  max-width: 1280px;
  width: 90% !important;

  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}
.footer__bottom-content--reverse {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 100%;
  width: 94%;
  font-size: 14px;
  padding-top: 20px;
}
.footer-contact {
  gap: 20px;
  font-size: 14px;
  font-family: "Nominee";
}

.footer__bottom-content--reverse a {
  color: #66585b;
}
.footer__copyright-content-wrapper {
  margin: auto auto auto 15px;
}
.footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-left: -50px;
}

@media only screen and (max-width: 920px) {
  .footer__bottom-content--reverse {
    flex-direction: row;
  }

  .footer-dwell-logo {
    margin-top: 19px;
  }

  .fulfilled-mention {
    margin-bottom: 30px;
  }

  .footer-logo {
    margin-left: -95px;
  }
}
@media only screen and (max-width: 540px) {
  .footer__bottom-content--reverse {
    flex-direction: column;
  }
  .hero-text-container,
  .product_section_header {
    width: 336px;
    margin: 0 auto;
    text-align: left !important;
  }
  .hero-container {
    padding-top: 25px;
  }
}
@media only screen and (max-width: 280px) {
  .fulfilled-mention {
    font-size: 13px;
  }

  .footer__bottom-content--reverse {
    text-align: center;
  }
}

.empty-cart {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flex-row-desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 85%;
  margin: auto;
}
.about-img {
  display: flex;
  width: 23%;
}
@media all and (max-width: 920px) {
  .flex-row-desktop {
    flex-direction: column;
    width: 95%;
  }
  .about-img {
    display: flex;
    width: 90%;
    margin-right: 0px;
    margin-bottom: 15px;
  }
}

.banner-margin {
  margin-top: 70px;
}

.css-dh0f3y {
  height: 580px;
  display: flex;
}

.css-1fkg5dg {
  position: relative;
  width: 100%;
  height: 100%;
}

.css-85ketc {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
}
.css-1b30rcz {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;
  flex-direction: column;
  z-index: 1;
  max-width: 1250px;
  width: 100%;
}
.banner-image {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: center center;

  filter: brightness(60%);
}
/* .cart-button {
} */
.css-1wll38a {
  font-family: Nominee, sans-serif;
  font-weight: 600;
  text-align: center;
  color: rgb(248, 249, 246);
  margin-bottom: 12px;
  font-size: 48px;

  line-height: 52px;
  letter-spacing: 8px;
}
.css-1b9w93t {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-transform: uppercase;
  padding: 8px 16px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  font-family: "Nominee Condensed", sans-serif;
  min-width: 221px;
  border-radius: 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 2.625px;
  background-color: rgb(248, 249, 246);
  color: rgb(123, 82, 34);
}
.css-1b9w93t:hover {
  background-color: rgb(123, 82, 34);
  color: rgb(248, 249, 246);
  text-decoration: none;
  box-shadow: none;
}
.read-more-btn {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 10px auto;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  font-family: "Nominee Condensed", sans-serif;
  min-width: 250px;
  border-radius: 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 2.625px;
  background-color: rgb(248, 249, 246);
  color: rgb(123, 82, 34);
  border: 1px solid rgb(123, 82, 34);
}
.read-more-btn:hover {
  background-color: rgb(123, 82, 34);
  color: rgb(248, 249, 246);
  text-decoration: none;
  box-shadow: none;
}
.emw-home-logo {
  height: 45px;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.storyCards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.storyCard-container {
  display: flex;
  flex-direction: column;

  margin: 10px auto;
  width: 28%;
  padding: 16px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.storyCard-container:hover {
  background-color: #ffff;
}

.storyCard-content {
  display: flex;
  flex-direction: column;
}

.storyCard-image {
  width: 100%;
  max-height: 280px;
  object-fit: cover;
}

.storyCard-text {
  padding-top: 16px;
  text-align: start;
  color: #846032;
}

.storyCard-title {
  margin: 0;
  text-align: start;
  text-transform: uppercase;
  font-family: "Nominee";
  letter-spacing: 4px;
  color: "#66585b";
  color: #846032;
  padding: 12px 0;
}

.storyCard-description {
  font-size: 16px;
  margin: 0;
  text-align: start;
  color: #846032;
  padding: 8px 0;
  font-weight: 300;
  letter-spacing: 2px;
}

.storyCard-link {
  letter-spacing: 2px;
  font-family: "Nominee";
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: start;
  text-decoration: underline;
  color: #846032;
}

.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
}

.flex-item {
  flex: 1 0 33.33%;
}

.flex-order-1 {
  order: 1;
}

.flex-order-2 {
  order: 2;
}

.flex-order-3 {
  order: 3;
}

.read-more-btn-mobile {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .flex-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .flex-order-1 {
    order: 2;
  }

  .flex-item {
    flex: auto;
  }

  .flex-order-2 {
    order: 1;
  }

  .flex-order-3 {
    display: none;
  }
  .read-more-btn-mobile {
    display: block;
    color: #846032 !important;
    max-width: 300px;
    background-color: transparent;
    border: 1px solid #846032;
    margin: 30px auto;
    min-height: 50px;
    justify-content: center;
    display: flex;
    align-items: center;
    font: 24px;
    font-weight: 600;
    width: 100%;
  }
  .content-data-container {
    flex-direction: column;
    margin: 20px 0;
  }
  .content-container {
    margin: 0 auto 1rem auto !important;
    padding: 20px !important;
  }
  .content-images {
    flex-direction: column;
  }
  .content-images > img {
    max-width: 100% !important;
  }

  .content-texts {
    margin: 0 0rem !important;
    padding: 0 0 16px 0 !important;
  }
  .content-info-text {
    text-align: left;
    margin: 0 0rem !important;
  }
  .content-info-detail {
    margin: 15px 0 !important;
    text-align: left;
  }
  .cart-button {
    margin-right: 20px;
  }
  .footer__bottom-content--reverse {
    align-items: start;
    width: 100%;
  }
  .header-container {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    z-index: 50;
    background-color: #f8f9f6;
    grid-template-columns: 0.5fr 10fr 4fr;
    display: grid;
  }
}

.content-container {
  border-bottom: solid 1px #5f5e5e;
  max-width: 1280px;
  margin: 0 auto 6rem auto;
  padding: 1.5rem 0 4rem 0;
  max-width: 90%;
}
.content-texts {
  padding: 0.5rem 0 3rem 0;
}

.content-title {
  display: flex;
  flex-direction: column;
  text-align: start;
  text-transform: uppercase;
}
.content-title-detail {
  text-align: start;
  margin: 20px 0 !important;
  font-size: 15px !important;
}

.content-title {
  font-family: "Nominee";
  color: #846032;
  font-size: 30px;
  padding: 0;
  margin: 0;
  letter-spacing: 2px;
}
.content-info-text {
  font-family: "Nominee";
  color: #846032;
  font-size: 30px;
  padding: 0;
  font-weight: bold;
  margin: 0;
}
.cart-button {
  font-family: "Nominee";
  color: #846032;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0;
  margin: 0;
}
.content-info-detail,
.content-title-detail {
  font-weight: 300 !important;
  color: #846032;
  padding: 0;
  margin: 0;
  margin-top: 5px;
  font-size: 20px;
  line-height: 28px;
}

.content-data-container {
  margin: 40px 0;
  display: flex;
  gap: 10px;
}

.content-images {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
}

.content-img {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.content,
.content-images {
  flex: 1;
}

.fulfilled_by,
.footer-contact {
  color: #846032;
  font-weight: 300;
  letter-spacing: 2px;
}

@media all and (max-width: 850px) {
  /* .header-svg {
    transform: scale(0.4);
  } */
  .noe-font-title {
    font-size: 29px;
    letter-spacing: -0.03em;
    line-height: 1.1;
    font-family: "Nominee";
    margin-bottom: 2.5rem;
  }

  .storyCards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    flex-direction: column;
  }
  .storyCard-container {
    width: 90%;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 920px) {
  .icon-wrapper {
    top: -5px;
  }
  .cart_icon {
    padding-top: 10px;
    position: absolute;
    top: 0;
    right: 10;
    right: 30px;
    top: 45px;
  }
  .nav-items {
    bottom: 10px;
    position: relative;
    margin-bottom: 0;
  }
  .icon-badge {
    top: 20px;
  }

  .hero-text-container {
    line-height: initial !important;
    justify-content: center;
    align-items: center;
    height: initial !important;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .product_section_header {
    text-align: center !important;
  }

  .emw-logo {
    position: relative;
    top: initial;
    left: initial;
  }

  .hero-h3 {
    width: 100%;
    text-align: left;
  }
  .hero-h1 {
    font-family: "Nominee";
    font-weight: 700;
    /* width: 90%;
		text-align: center !important; */
    padding-bottom: 10px;
  }
  .section-2-bottom-header-button-wrapper {
    padding-top: 15px !important;
  }
  .collection-container {
    margin-bottom: 2em !important;
  }
  .nav-items {
    gap: 35px;
    padding-left: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .emw-home-logo img {
    width: 100%;
    height: auto;
    max-width: 250px;
  }

  .read-more-btn {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .emw-home-logo {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: -28px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .emw-home-logo img {
    max-width: 360px;
  }
}

.contact_span {
  margin-top: 20px;
}
@media only screen and (max-width: 671px) {
  .p_break {
    display: block;
  }
  .cart_icon {
    right: 14px;
    top: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .icon-wrapper {
    top: -10px;
    right: 20px;
  }
  .cart_icon {
    right: 14px;
    top: 15px;
  }
  .icon-badge {
    top: 17px;
  }
  .noe-font-title.hero-h1 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 360px) {
  .icon-badge {
    top: 17px;
  }
}
@media only screen and (max-width: 320px) {
  .header-svg {
    transform: scale(0.9);
  }
  .nav-items {
    gap: 25px;
  }
}
@media only screen and (max-width: 280px) {
  .emw-logo {
    position: relative;
    top: 10px;
  }
  .icon-wrapper {
    top: -5px;
  }
  .icon-badge {
    top: 17px;
  }
  .noe-font-title.hero-h1 {
    font-size: 29px;
  }
  .nav-items {
    gap: 15px;
  }
}
