body {
  margin: 0;
  background-color: #f8f9f6;
  font-family: 'Nominee', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* 
@font-face {
  font-family: "NoeSemibold";
  src: local("NoeSemibold"), url("../../Fonts/Noe/Noe-Text-Semibold.woff") format("opentype");
} */


@font-face {
  font-family: 'Nominee';
  src: local("Nominee"), url('https://www.elevenmadisonhome.com/fonts/Nominee-Light.woff2') format('opentype');
  font-weight: 300; /* light */
  font-style: normal;
}
@font-face {
  font-family: 'Nominee';
  src: local("Nominee"), url('https://www.elevenmadisonhome.com/fonts/Nominee-MediumCondensed.woff2') format('opentype'), url('https://www.elevenmadisonhome.com/fonts/Nominee-MediumExtended.woff2') format('opentype');
  font-weight: 500; /* medium */
  font-style: normal;
}
@font-face {
  font-family: 'Nominee';
  src: local("Nominee"), url('https://www.elevenmadisonhome.com/fonts/Nominee-Bold.woff2') format('opentype');
  font-weight: 700; /* bold */
  font-style: normal;
}

/* @font-face {
  font-family: 'NocturnSerif';
  src: local('NocturnSerif'), url('../../Fonts/NocturnSerif/NocturneSerif-Regular.ttf');
}

@font-face {
  font-family: 'PTSerifBold';
  src: local('PTSerifBold'), url('../../Fonts/Adieu/Adieu-Regular.woff');
}
@font-face {
  font-family: 'PTSerifRegular';
  src: local('PTSerifRegular'), url('../../Fonts/PT_Serif/PTSerif-Regular.ttf');
} */

.loader,
.loader:after {
  border-radius: 50%;
  width: 1.4em;
  height: 1.4em;
}
.loader {
  margin: 2px auto;
  font-size: 14px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.25em solid rgba(255, 255, 255, 0.2);
  border-right: 0.25em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.25em solid rgba(255, 255, 255, 0.2);
  border-left: 0.25em solid #ffffff00;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.onwhite {
  border-top: 0.25em solid rgba(37, 37, 37, 0.719);
  border-right: 0.25em solid rgba(37, 37, 37, 0.719);
  border-bottom: 0.25em solid rgba(37, 37, 37, 0.719);
  border-left: 0.25em solid #ffffff00;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
